import React, { useState } from 'react';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Lightbox from 'react-image-lightbox';
import eventsJson from 'constants/events.json';
import styles from './Events.module.css';

const Events = (props) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [eventsIndex, setEventsIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const events = eventsJson.events

  const openImageLightbox = (eventIndex, index) => {
    setEventsIndex(eventIndex)
    setPhotoIndex(index)
    setIsOpen(true)
  }

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h1>Events</h1>
      </div>
      <div className={styles.events}>
        {events.map((event, eventIndex) => (
          <div key={eventIndex}>
            <div className={styles.title}>{event.name}</div>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <Masonry>
                {event.photos.map((photo, index) => (
                  <img className={styles.image} key={index} src={photo.url} alt="" onClick={() => openImageLightbox(eventIndex, index)} />
                ))}
              </Masonry>
            </ResponsiveMasonry>
            {isOpen && (
              <Lightbox
                mainSrc={events[eventsIndex].photos[photoIndex].url}
                nextSrc={events[eventsIndex].photos[(photoIndex + 1) % events[eventsIndex].photos.length].url}
                prevSrc={events[eventsIndex].photos[(photoIndex + events[eventsIndex].photos.length - 1) % events[eventsIndex].photos.length].url}
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex((photoIndex + events[eventsIndex].photos.length - 1) % events[eventsIndex].photos.length)
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % events[eventsIndex].photos.length)
                }
              />
            )}
            <div className={styles.divider} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Events