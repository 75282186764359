import React from 'react';
import styles from './Services.module.css';

const Services = () => {
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h1>Services</h1>
        <p>Jamal is a photographer based in Brooklyn, NY. Email at&nbsp;
          <a 
          href="mailto:vizualphotography1@gmail.com" 
          target="_blank"
          rel="noopener noreferrer">
            vizualphotography1@gmail.com
          </a> 
          &nbsp;for questions, pricing quotes, and booking requests.
        </p>
      </div>
      <div className={styles.servicesCollection}>
        <div className={styles.service}>
          <div className={styles.serviceText}>
            <div className={styles.serviceName}>Portraits</div>
            <p>Personal portraits, family photos, engagements, graduations, pregnancy, babies, pets, etc.</p>
          </div>
          <img src="https://i.imgur.com/SMKc5hB.jpg" alt="Portrait - personal"/>
        </div>

        <div className={styles.serviceSeparator}/>

        <div className={styles.service}>
          <div className={styles.serviceText}>
            <div className={styles.serviceName}>Events</div> 
            <p>Birthdays, holiday parties, wedding/baby showers, etc.</p>
          </div>
          <img src="https://i.imgur.com/dRNFoqn.jpg" alt="Events - baby shower"/>
        </div>

        <div className={styles.serviceSeparator} />

        <div className={styles.service}>
          <div className={styles.serviceText}>
            <div className={styles.serviceName}>Product</div>
            <p>Clothing lines, food & drink, jewelry, etc</p>
          </div>
          <img src="https://i.imgur.com/AmMnLMZ.jpg" alt="Product - t shirt line"/>
        </div>
      </div>
    </div>
  )
}

export default Services
