import React, { useState } from 'react';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Lightbox from 'react-image-lightbox';
import styles from './Gallery.module.css'

const Gallery = (props) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const photos = props.photos

  const openImageLightbox = (index) => {
    console.log('index', index)
    setPhotoIndex(index)
    setIsOpen(true)
  }
  
  return (
    <div className={styles.main}>
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
      >
        <Masonry>
          {photos.map((photo, index) => (
            <img className={styles.image} key={index} src={photo.url} alt={photo.alt} onClick={() => openImageLightbox(index)}/>
          ))}
        </Masonry>
      </ResponsiveMasonry>
      {isOpen && (
        <Lightbox
          mainSrc={photos[photoIndex].url}
          nextSrc={photos[(photoIndex + 1) % photos.length].url}
          prevSrc={photos[(photoIndex + photos.length - 1) % photos.length].url}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % photos.length)
          }
        />
      )}
    </div>
  )
}

export default Gallery;