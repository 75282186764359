import React from 'react';
import Gallery from 'components/Gallery'
import favoritesJson from 'constants/favorites.json';
import styles from './Homepage.module.css';

const Homepage = (props) => {
  const photos = favoritesJson.photos

  console.log('photos', photos)

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h1>Jae Vizuals Photography</h1>
      </div>
      <Gallery photos={photos} />
    </div>
  )
}

export default Homepage