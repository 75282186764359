import React from 'react'
import { Switch, Route } from 'react-router'
import Header from 'components/Header'
import Homepage from 'scenes/Homepage'
import Photoshoot from 'scenes/Photoshoot'
import PhotoSeries from 'scenes/PhotoSeries'
import Events from 'scenes/Events'
import Services from 'scenes/Services'
import NotFoundPage from 'components/NotFoundPage'
import styles from './App.module.css';
import 'react-image-lightbox/style.css';

class App extends React.Component {
  render() {
    return (
      <main className={styles.main}>
        <Header/>
        <div className={styles.content}>
          <Switch>
            <Route path="/services" component={Services} />
            <Route path="/photo-series" component={PhotoSeries} />
            <Route path="/events" component={Events} />
            <Route 
              path="/:name" 
              exact
              render={(routerProps) => (
                <Photoshoot
                  history={routerProps.history}
                  match={routerProps.match}
                />
              )}/>
            <Route
              path="/"
              exact
              render={(routerProps) => (
                <Homepage
                  history={routerProps.history}
                />
              )} />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
      </main>
    )
  }
}

export default App