import React from 'react'
import styles from './NotFoundPage.module.css'

const NotFoundPage = () => (
  <div className={styles.main}>
    <h1>Oh no! That page doesn't exist.</h1>
    <p>
      It looks like the page you were trying to reach doesn't exist anymore. Try
      heading back to the
      <a className={styles.link} href="/">
        home page
      </a>.
    </p>
  </div>
)

export default NotFoundPage
