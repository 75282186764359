import React, { useState } from 'react';
import {
  Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';

const Header = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <Navbar light expand="md">
      <NavbarBrand href="/" className="mr-auto">jaevizuals</NavbarBrand>
      <NavbarToggler onClick={toggleNavbar} />
      <Collapse isOpen={!collapsed} navbar>
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Portfolio
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <NavLink href="/portrait">Portrait</NavLink>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <NavLink href="/photo-series">Photo Series</NavLink>
              </DropdownItem>          
              <DropdownItem divider />
              <DropdownItem>
                <NavLink href="/events">Events</NavLink>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <NavLink href="/candids">Candids</NavLink>
              </DropdownItem>
              {/* <DropdownItem divider />
              <DropdownItem>
                <NavLink href="/street">Street</NavLink>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <NavLink href="/landscape">Landscape</NavLink>
              </DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem>
            <NavLink href="/services">Services</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://instagram.com/jaevizuals" target="_blank" rel="noopener noreferrer">
              @jaevizuals
              </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default Header;