import React, { useState } from 'react';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Lightbox from 'react-image-lightbox';
import photoSeriesJson from 'constants/photoSeries.json';
import styles from './PhotoSeries.module.css';

const PhotoSeries = (props) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [photoSeriesIndex, setSeriesIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const photoSeries = photoSeriesJson.photoSeries

  const openImageLightbox = (seriesIndex, index) => {
    setSeriesIndex(seriesIndex)
    setPhotoIndex(index)
    setIsOpen(true)
  }

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h1>Photo Series</h1>
      </div>
      <div className={styles.events}>
        {photoSeries.map((series, seriesIndex) => (
          <div key={seriesIndex}>
            <div className={styles.title}>{series.name}</div>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <Masonry>
                {series.photos.map((photo, index) => (
                  <img className={styles.image} key={index} src={photo.url} alt="" onClick={() => openImageLightbox(seriesIndex, index)} />
                ))}
              </Masonry>
            </ResponsiveMasonry>
            {isOpen && (
              <Lightbox
                mainSrc={photoSeries[photoSeriesIndex].photos[photoIndex].url}
                nextSrc={photoSeries[photoSeriesIndex].photos[(photoIndex + 1) % photoSeries[photoSeriesIndex].photos.length].url}
                prevSrc={photoSeries[photoSeriesIndex].photos[(photoIndex + photoSeries[photoSeriesIndex].photos.length - 1) % photoSeries[photoSeriesIndex].photos.length].url}
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex((photoIndex + photoSeries[photoSeriesIndex].photos.length - 1) % photoSeries[photoSeriesIndex].photos.length)
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % photoSeries[photoSeriesIndex].photos.length)
                }
              />
            )}
            <div className={styles.divider} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default PhotoSeries