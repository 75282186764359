import React from 'react';
import Gallery from 'components/Gallery'
import styles from './Photoshoot.module.css';
import galleriesJson from 'constants/photoshoots.json'

const Photoshoot = (props) => {
  const galleries = galleriesJson.photoshoots
  const gallery = galleries.find(gallery => gallery.param === props.match.params.name)
  console.log('gallery', gallery)

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h1>{gallery.name}</h1>
      </div>
      <Gallery photos={gallery.photos} />
    </div>
  )
}

export default Photoshoot